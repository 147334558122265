export const rtlLanguageCodes = [
    'ar', // Arabic
    'arc', // Aramaic
    'dv', // Dhivehi/Maldivian
    'fa', // Persian
    'ha', // Hausa
    'he', // Hebrew
    'khw', // Khowar
    'ks', // Kashmiri
    'ku', // Kurdish
    'ps', // Pashto
    'ur', // Urdu
    'yi' // Yiddish
];

export const isRtlLocal = (local) => {
    const localParts = local.split('-');

    const baseLang = localParts[0];

    return rtlLanguageCodes.indexOf(baseLang) !== -1;
}

