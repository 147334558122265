import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ImInstagram, ImTwitter } from 'react-icons/im';
import { isRtlLocal } from './helpers/browser-is-rtl';
import './App.css';

const language = window?.navigator?.language || 'en';
const browserLangLower = language.toLocaleLowerCase();
const rtlClass = isRtlLocal(browserLangLower) ? ' rtl' : '';
let theme = 'light';

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  theme = 'dark';
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
  theme = event.matches ? 'dark' : 'light';
  document.getElementById('body').className = theme + rtlClass;
});

document.getElementById('body').className = theme + rtlClass;

document.getElementById('html').lang = language;

const Home = React.lazy(() => import('./Home'));
const Payments = React.lazy(() => import('./Payments'));
const Success = React.lazy(() => import('./Success'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = React.lazy(() => import('./pages/TermsOfUse'));

function App(props) {
  return (
    <>
      <h1 style={{ cursor: 'pointer' }} onClick={() => { window.location.href = '/' }} >GPTSMS.ai</h1>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <React.Suspense fallback={<>...</>}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path="/card"
            element={
              <React.Suspense fallback={<>...</>}>
                <Payments />
              </React.Suspense>
            }
          />
          <Route
            path="/success"
            element={
              <React.Suspense fallback={<>...</>}>
                <Success />
              </React.Suspense>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <React.Suspense fallback={<>...</>}>
                <PrivacyPolicy />
              </React.Suspense>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <React.Suspense fallback={<>...</>}>
                <TermsOfUse />
              </React.Suspense>
            }
          />
          <Route
            path="/terms-of-service"
            element={
              <React.Suspense fallback={<>...</>}>
                <TermsOfUse />
              </React.Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
      <hr />
      <footer>
        <div>
          © <span id="year">2023</span>
          {" - "}
          GPTSMS.ai
          {" - "}
          <a aria-label="Twitter" target="_blank" rel="noreferrer" href={"https://twitter.com/gptsmsai"}>
            <ImTwitter />
          </a>
          {" - "}
          <a aria-label="Instagram" target="_blank" rel="noreferrer" href={"https://www.instagram.com/gptsmsai/"}>
            <ImInstagram />
          </a>
        </div>
        <hr />
        <div>
          <a href="/terms-of-service">Terms of Service</a> | <a href="/privacy-policy">Privacy</a>
        </div>
      </footer>
    </>
  );
}

export default App;
